// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // test: "http://54.189.38.92:8000/api/",
  // test: "http://localhost:8000/api/",
  // test: "https://backend.rayxdx.com/api/",
  // test: "http://10.10.10.68:8000/api/",
  test :"https://backend.rayxdx.com/api/",

  // test :"https://backendstaging.rayxdx.com/api/",
  accessKeyId: "AKIARIX3IWZQPEA4IPO6",
  secretAccessKey: "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",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
